/**
 * This is a temporary hook where we handle user invitation through a link
 */

import { gql } from '@apollo/client';
import {
  MeFragmentUserQueriesFragment,
  ShareEntityType,
  useConsumeShareLinkForUseInvitationThroughLinkMutation,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getCustomOperationContext } from 'utils/apollo';
import { InvitationThroughLinkInvitedToCollectionView } from '../../views';

const SESSION_STORAGE_REFERRAL_SOURCE = 'referralSource';
const SESSION_STORAGE_INVITE_TO = 'inviteTo';
const SESSION_STORAGE_INVITE_VALIDATION_TOKEN = 'validationToken';

// eslint-disable-next-line
gql`
  mutation ConsumeShareLinkForUseInvitationThroughLink(
    $data: ConsumeShareLinkInput!
  ) {
    consumeShareLink(data: $data) {
      success
      message
    }
  }
`;

export type UseInvitationThroughLinkProps = {
  user?: MeFragmentUserQueriesFragment | null;
};

export const useInvitationThroughLink = (
  props: UseInvitationThroughLinkProps,
) => {
  const { user } = props;

  const [params] = useSearchParams();
  const navigate = useNavigate();

  const [consumeShareLink] =
    useConsumeShareLinkForUseInvitationThroughLinkMutation({
      context: getCustomOperationContext({
        suppressTopLevelToast: true,
      }),
    });

  const [referralSource, setReferralSource] = useState(
    params.get('referralSource') ||
      window.sessionStorage.getItem(SESSION_STORAGE_REFERRAL_SOURCE) ||
      '',
  );

  const [inviteTo, setInviteTo] = useState(
    params.get('inviteTo') ||
      window.sessionStorage.getItem(SESSION_STORAGE_INVITE_TO) ||
      '',
  );

  const [validationToken, setValidationToken] = useState(
    params.get('validationToken') ||
      window.sessionStorage.getItem(SESSION_STORAGE_INVITE_VALIDATION_TOKEN) ||
      '',
  );

  const [shouldRenderInvitedView, setShouldRenderInvitedView] = useState(false);
  const [entityTypeToRender, setEntityTypeToRender] = useState('');
  const [entityIdToRender, setEntityIdToRender] = useState('');

  const [shouldSkipOnboarding, setShouldSkipOnboarding] = useState(false);

  useEffect(() => {
    const newParams = new URLSearchParams(params);

    if (params.get('referralSource')) {
      setReferralSource(params.get('referralSource') || '');
      window.sessionStorage.setItem(
        SESSION_STORAGE_REFERRAL_SOURCE,
        params.get('referralSource') || '',
      );
      newParams.delete('referralSource');
    }

    if (params.get('inviteTo')) {
      setInviteTo(params.get('inviteTo') || '');
      window.sessionStorage.setItem(
        SESSION_STORAGE_INVITE_TO,
        params.get('inviteTo') || '',
      );
      newParams.delete('inviteTo');

      // validationToken is to be consumed together with inviteTo
      if (params.get('validationToken')) {
        setValidationToken(params.get('validationToken') || '');
        window.sessionStorage.setItem(
          SESSION_STORAGE_INVITE_VALIDATION_TOKEN,
          params.get('validationToken') || '',
        );
        newParams.delete('validationToken');
      }
    }

    navigate({ search: newParams.toString() });
  }, [params]); // eslint-disable-line

  const clearReferralSource = () => {
    setReferralSource('');
    window.sessionStorage.removeItem(SESSION_STORAGE_REFERRAL_SOURCE);
  };

  const clearInviteTo = () => {
    setInviteTo('');
    setValidationToken('');

    window.sessionStorage.removeItem(SESSION_STORAGE_INVITE_TO);
    window.sessionStorage.removeItem(SESSION_STORAGE_INVITE_VALIDATION_TOKEN);
  };

  const onCloseInvitedView = () => {
    setShouldRenderInvitedView(false);
    setEntityIdToRender('');
    setEntityTypeToRender('');

    setShouldSkipOnboarding(false);
  };

  // Invite user to certain entities if
  // 1. User is logged in
  // 2. params.inviteTo is provided
  useEffect(() => {
    (async () => {
      const [inviteToEntityType, inviteToEntityId] = inviteTo.split(':');

      if (user && inviteToEntityType && inviteToEntityId) {
        switch (inviteToEntityType) {
          case ShareEntityType.Collection: {
            if (validationToken) {
              await consumeShareLink({
                variables: {
                  data: {
                    entityId: inviteToEntityId,
                    entityType: inviteToEntityType,
                    validationToken,
                  },
                },
              });
            }

            break;
          }
          default: {
            break;
          }
        }

        setShouldRenderInvitedView(true);
        setEntityIdToRender(inviteToEntityId);
        setEntityTypeToRender(inviteToEntityType);

        setShouldSkipOnboarding(true);

        clearInviteTo();
      }
    })();
  }, [user, inviteTo]); // eslint-disable-line

  const renderInvitedView = () => {
    if (!shouldRenderInvitedView) {
      return null;
    }

    switch (entityTypeToRender) {
      case ShareEntityType.Collection: {
        return (
          <InvitationThroughLinkInvitedToCollectionView
            collectionId={entityIdToRender}
            onClose={onCloseInvitedView}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  return {
    referralSource,
    shouldSkipOnboarding,
    clearReferralSource,
    renderInvitedView,
  };
};
