import { gql, useApolloClient } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { useUserContext } from 'contexts/users/User.context';
import {
  COLLECTION_INVITE_MEMBER_FRAGMENT_COLLECTION_PERMISSION_MEMBER,
  CollectionPermissionMember,
} from 'features/collectionPermission/components';
import {
  UpdateCollectionPermissionsDataInput,
  useCollectionPermissionMutations,
} from 'features/collectionPermission/hooks';
import { useCollectionPermissionUpdateNestedCollection } from 'features/collectionPermission/hooks/useCollectionPermissionUpdateNestedCollection';
import {
  GeneralPermissionSelector,
  PermissionApproveRequest,
  PermissionCreateRequest,
  PermissionMemberItem,
  useCopyLink,
  usePermissionAddMemberInput,
  usePermissionUserSearch,
} from 'features/permission';
import { USER_PROFILE_FRAGMENT_USER_PROFILE_PERMISSION_ITEM_VIEW } from 'features/user-profile';
import {
  CollectionFragmentCollectionPermissionDialogBodyFragment,
  CollectionInviteMemberFragmentCollectionPermissionMemberFragment,
  CollectionPermission,
  GeneralPermission,
  PermissionLevel,
  PermissionRequestFragmentPermissionApproveRequestFragmentDoc,
  ShareEntityType,
  UserProfileFragmentUserProfilePermissionItemViewFragment,
} from 'graphql/generated';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import { useEffect, useMemo, useRef, useState } from 'react';
import { theme } from 'styles/theme';
import { useImmer } from 'use-immer';
import { evictObject } from 'utils/apollo';
import { getFullName } from 'utils/users';
import { CollectionPermissionUpdatesDialog } from '../permissionUpdatesDialog';

export const COLLECTION_FRAGMENT_COLLECTION_PERMISSION_DIALOG_BODY = gql`
  fragment CollectionFragmentCollectionPermissionDialogBody on CollectionModel {
    id
    name
    myPermissions
    generalPermission
    permissionLevel
    organizationId
    organizationName
    myPermissions
    permissionRequests {
      ...PermissionRequestFragmentPermissionApproveRequest
    }
    creator {
      ...UserProfileFragmentUserProfilePermissionItemView
    }
    inviteMembers {
      ...CollectionInviteMemberFragmentCollectionPermissionMember
    }
    childCollections {
      id
    }
    posts(postCount: 3) {
      id
    }
  }
  ${USER_PROFILE_FRAGMENT_USER_PROFILE_PERMISSION_ITEM_VIEW}
  ${COLLECTION_INVITE_MEMBER_FRAGMENT_COLLECTION_PERMISSION_MEMBER}
  ${PermissionRequestFragmentPermissionApproveRequestFragmentDoc}
`;

type CollectionPermissionDialogBodyProps = {
  collection: CollectionFragmentCollectionPermissionDialogBodyFragment;
  onRequestCreateAccess: VoidFunction;
  onCallbackAfterUpdate?: (data: { showCustomSelectionView?: boolean }) => void;
  setHasPendingChanges?: (state: boolean) => void;
  updateBtnRef?: React.RefObject<HTMLButtonElement>;

  inputData: UpdateCollectionPermissionsDataInput | null;
  setInputData: (data: UpdateCollectionPermissionsDataInput) => void;

  setShowingUpdatesConfirmation: (state: boolean) => void;
};

export const CollectionPermissionDialogBody = (
  props: CollectionPermissionDialogBodyProps,
) => {
  const {
    collection,
    onCallbackAfterUpdate,
    setHasPendingChanges,
    updateBtnRef,
    onRequestCreateAccess,
    inputData: existingInputData,
    setInputData,
    setShowingUpdatesConfirmation,
  } = props;
  const { renderCopyButton, renderCopyLinkToast } = useCopyLink({
    entityId: collection.id,
    entityType: ShareEntityType.Collection,
  });
  const { handleUpdateNestedCollectionPostPermissions, savingData } =
    useCollectionPermissionUpdateNestedCollection({
      collectionId: collection.id,
    });

  const searchInputRef = useRef<HTMLInputElement>(null);

  const { user, isWorkOrganization } = useUserContext();
  const navigate = useGuardNavigate();

  const originalInviteMembers: CollectionInviteMemberFragmentCollectionPermissionMemberFragment[] =
    useMemo(() => {
      return collection.inviteMembers;
    }, [collection.inviteMembers]);

  const [inviteMembers, setInviteMembers] = useImmer<
    CollectionInviteMemberFragmentCollectionPermissionMemberFragment[]
  >(originalInviteMembers);

  // update inviteMembers whenever collection data changes
  useEffect(() => {
    setInviteMembers(originalInviteMembers);
  }, [originalInviteMembers]); // eslint-disable-line react-hooks/exhaustive-deps

  const invitedUser = useMemo(() => {
    if (
      collection.creator.id === user?.id ||
      collection.myPermissions.includes(CollectionPermission.Update)
    ) {
      return null;
    }
    const _invitedUser = inviteMembers.find((m) => m.user.id === user?.id);

    return _invitedUser || user;
  }, [inviteMembers, collection, user]);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [newSelectedUsers, setNewSelectedUsers] = useState<
    UserProfileFragmentUserProfilePermissionItemViewFragment[]
  >([]);
  const [newSelectedUsersPermission, setNewSelectedUsersPermission] =
    useState<PermissionLevel>(PermissionLevel.View);
  const [note, setNote] = useState('');
  const [shouldCreateComment, setShouldCreateComment] = useState(true);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const hasFullAccess = collection.myPermissions.includes(
    CollectionPermission.Update,
  );

  useEffect(() => {
    setShowingUpdatesConfirmation(showConfirmation);
  }, [showConfirmation]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    renderInput,
    toggleUserSelect,
    resetInput,
    renderPermissionSelectView,
  } = usePermissionAddMemberInput({
    entityOrganizationId: collection.organizationId,
    onSearchStrUpdate: setInputValue,
    onMembersUpdate: setNewSelectedUsers,
    onPermissionUpdate: setNewSelectedUsersPermission,
    onShouldCreateCommentUpdate: setShouldCreateComment,
    onNoteUpdate: setNote,
  });

  const [overallPermission, setOverallPermission] = useImmer<{
    generalPermission: GeneralPermission;
    permission?: PermissionLevel;
  }>({
    generalPermission: collection.generalPermission,
    permission: collection.permissionLevel,
  });

  const { suggestedUsers } = usePermissionUserSearch({
    searchStr: inputValue,
    existingMembers: inviteMembers.map((x) => x.user),
  });

  const filterExistingMembers = useMemo(() => {
    return inviteMembers.filter(
      (m) =>
        m.user.email.toLowerCase().includes(inputValue.toLowerCase()) ||
        (getFullName(m.user).toLowerCase().includes(inputValue.toLowerCase()) &&
          m.id !== user?.id),
    );
  }, [inviteMembers, inputValue, user]);

  const [loading, setLoading] = useState(false);

  const handleInviteMemberPermissionChange = (
    inviteMember: CollectionInviteMemberFragmentCollectionPermissionMemberFragment,
    remove?: boolean,
  ) => {
    const memberIndex = inviteMembers.findIndex(
      (c) => c.id === inviteMember.id,
    );

    if (memberIndex === -1) {
      return;
    }

    if (remove) {
      setInviteMembers((draft) => {
        draft.splice(memberIndex, 1);
      });
    } else {
      setInviteMembers((draft) => {
        draft[memberIndex].permissionLevel = inviteMember.permissionLevel;
      });
    }
  };

  const hasExistingMemberDataChanged = useMemo(() => {
    return (
      JSON.stringify(originalInviteMembers) !== JSON.stringify(inviteMembers)
    );
  }, [originalInviteMembers, inviteMembers]);

  const hasOverallPermissionDataChanged = useMemo(() => {
    return (
      collection.generalPermission !== overallPermission.generalPermission ||
      collection.permissionLevel !== overallPermission.permission
    );
  }, [
    collection.generalPermission,
    collection.permissionLevel,
    overallPermission,
  ]);

  const isThereNewInvite = useMemo(() => {
    return newSelectedUsers.length > 0;
  }, [newSelectedUsers]);

  const { updateCollectionPermissions } = useCollectionPermissionMutations({
    collectionIds: [collection.id],
  });
  const client = useApolloClient();

  const updatePermissions = async () => {
    const updatedUserList: { email: string; permission: PermissionLevel }[] =
      [];
    const userIdsToBeRemoved: string[] = [];

    if (hasExistingMemberDataChanged) {
      updatedUserList.push(
        ...inviteMembers.map((n) => ({
          email: n.user.email,
          permission: n.permissionLevel,
        })),
      );

      const collectionInviteMemberIds = inviteMembers.map((m) => m.id);
      userIdsToBeRemoved.push(
        ...originalInviteMembers
          .filter((m) => !collectionInviteMemberIds.includes(m.id))
          .map((m) => m.user.id),
      );
    }

    if (isThereNewInvite) {
      updatedUserList.push(
        ...newSelectedUsers.map((n) => ({
          email: n.email,
          permission: newSelectedUsersPermission,
        })),
      );
    }

    const inputData: UpdateCollectionPermissionsDataInput = {
      ...existingInputData,
    };

    if (updatedUserList.length > 0) {
      inputData.inviteMembers = {
        members: updatedUserList,
      };
    }

    if (userIdsToBeRemoved.length > 0) {
      inputData.removeMembers = {
        userIds: userIdsToBeRemoved,
      };
    }

    if (hasOverallPermissionDataChanged) {
      inputData.generalPermission = {
        generalPermission: overallPermission.generalPermission,
        permissionLevel: overallPermission.permission,
      };
    }

    inputData.note = note;
    inputData.shouldCreateComment = shouldCreateComment;

    let showConfirmation = false;
    const existingCollectionUsers = collection.inviteMembers.map(
      (m) => m.user.email,
    );
    const newInvitedEmails = inputData?.inviteMembers?.members
      .map((m) => m.email)
      .filter((email) => !existingCollectionUsers.includes(email));

    const collectionHasData =
      collection.posts.length > 0 || collection.childCollections.length > 0;

    if (
      collectionHasData &&
      (newInvitedEmails?.length ||
        inputData?.removeMembers?.userIds.length ||
        (inputData?.generalPermission &&
          inputData?.generalPermission.generalPermission !==
            collection.generalPermission))
    ) {
      showConfirmation = true;
    }
    setInputData(inputData);

    handleUpdate(inputData);
    if (showConfirmation) {
      setShowConfirmation(showConfirmation);
    } else {
      onCallbackAfterUpdate?.({ showCustomSelectionView: false });
    }
  };

  const handleUpdate = async (data?: UpdateCollectionPermissionsDataInput) => {
    const _inputData = { ...existingInputData, ...data };
    setLoading(true);
    if (!_inputData) {
      throw new Error('No input data');
    }
    await updateCollectionPermissions(_inputData);
    resetInput();
    setLoading(false);

    // when user removes his/her access to the collection, we gracefully return them back to the previous location
    if (
      user &&
      collection.creator.id !== user.id &&
      overallPermission.generalPermission === GeneralPermission.InviteOnly &&
      (_inputData?.removeMembers?.userIds.includes(user.id) ||
        !_inputData?.inviteMembers?.members
          .map((m) => m.email)
          .includes(user.email))
    ) {
      evictObject(client.cache, collection.id, 'CollectionModel');
      navigate(PlotRoutes.juicebox());
    }
  };

  const isUpdateDisabled =
    (!hasExistingMemberDataChanged &&
      !isThereNewInvite &&
      !hasOverallPermissionDataChanged) ||
    loading;
  useEffect(() => {
    setHasPendingChanges?.(!isUpdateDisabled);
  }, [isUpdateDisabled]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.onfocus = () => {
        setIsInputFocused(true);
      };
      searchInputRef.current.onblur = () => {
        setTimeout(() => {
          setIsInputFocused(false);
        }, 100);
      };
    }
  }, [searchInputRef]);

  const renderMembers = useMemo(() => {
    if (inputValue) {
      return (
        <>
          {filterExistingMembers.length > 0 && (
            <Box sx={{ p: theme.spacing(4, 6) }}>
              <Box mb={2}>
                <Typography
                  fontWeight={600}
                  variant="body-lg"
                  color={theme.colors?.utility['800']}
                >
                  In this collection
                </Typography>
              </Box>

              {filterExistingMembers.map((inviteMember) => (
                <CollectionPermissionMember
                  key={inviteMember.id}
                  collectionOrganizationId={collection.organizationId}
                  collectionInviteMember={inviteMember}
                  onPermissionChange={handleInviteMemberPermissionChange}
                  readonly={!hasFullAccess}
                />
              ))}
            </Box>
          )}

          <Box
            sx={{
              p: theme.spacing(4, 6),
              paddingTop: filterExistingMembers.length === 0 ? 4 : 0,
            }}
          >
            {suggestedUsers.length === 0 && inputValue !== '' && (
              <Typography
                fontWeight={600}
                variant="body-lg"
                color={theme.colors?.utility['800']}
              >
                Keep typing a valid email...
              </Typography>
            )}
            {suggestedUsers.length > 0 && (
              <>
                <Box mb={4}>
                  <Typography
                    fontWeight={600}
                    variant="body-lg"
                    color={theme.colors?.utility['800']}
                  >
                    Not in this collection
                  </Typography>
                </Box>
                {suggestedUsers.slice(0, 5).map((suggestedUser) =>
                  user ? (
                    <PermissionMemberItem
                      key={suggestedUser.id}
                      entityOrganizationId={collection.organizationId}
                      totalMembers={
                        newSelectedUsers.filter(
                          (u) =>
                            !user?.organization.users
                              .filter(
                                (user) => user.shouldBeCountedTowardPaywall,
                              )
                              .some(
                                (internalUser) => internalUser.id === u.id,
                              ) &&
                            u.organization.id === collection.organizationId,
                        ).length
                      }
                      totalGuests={
                        newSelectedUsers.filter((u) => {
                          if (isWorkOrganization) {
                            return (
                              !user?.organization.externalUsers
                                .filter(
                                  (user) => user.shouldBeCountedTowardPaywall,
                                )
                                .some((guest) => guest.id === u.id) &&
                              u.organization.id !== collection.organizationId
                            );
                          }

                          // everyone is a guests for non-work organization
                          return user?.organization.users
                            .filter((user) => user.shouldBeCountedTowardPaywall)
                            .every((internalUser) => internalUser.id !== u.id);
                        }).length
                      }
                      userProfile={suggestedUser}
                      type="suggested"
                      readonly={!hasFullAccess}
                      endAdornmentProps={{
                        suggested: {
                          isSelected: !!newSelectedUsers.find(
                            (u) => u.email === suggestedUser.email,
                          ),
                          toggleSelect: toggleUserSelect,
                        },
                      }}
                    />
                  ) : null,
                )}
              </>
            )}
          </Box>
        </>
      );
    }
    if (inviteMembers.length === 0 && isInputFocused) {
      return (
        <Box sx={{ p: theme.spacing(4, 6) }}>
          <Box mb={4}>
            <Typography
              fontWeight={600}
              variant="body-lg"
              color={theme.colors?.utility['800']}
            >
              Suggested
            </Typography>
          </Box>
          {suggestedUsers.slice(0, 6).map((suggestedUser) =>
            user ? (
              <PermissionMemberItem
                key={suggestedUser.id}
                userProfile={suggestedUser}
                entityOrganizationId={collection.organizationId}
                totalMembers={
                  newSelectedUsers.filter(
                    (u) =>
                      !user?.organization.users
                        .filter((user) => user.shouldBeCountedTowardPaywall)
                        .some((internalUser) => internalUser.id === u.id) &&
                      u.organization.id === collection.organizationId,
                  ).length
                }
                totalGuests={
                  newSelectedUsers.filter((u) => {
                    if (isWorkOrganization) {
                      return (
                        !user?.organization.externalUsers
                          .filter((user) => user.shouldBeCountedTowardPaywall)
                          .some((guest) => guest.id === u.id) &&
                        u.organization.id !== collection.organizationId
                      );
                    }

                    // everyone is a guests for non-work organization
                    return user?.organization.users
                      .filter((user) => user.shouldBeCountedTowardPaywall)
                      .every((internalUser) => internalUser.id !== u.id);
                  }).length
                }
                type="suggested"
                readonly={!hasFullAccess}
                endAdornmentProps={{
                  suggested: {
                    isSelected: !!newSelectedUsers.find(
                      (u) => u.email === suggestedUser.email,
                    ),
                    toggleSelect: toggleUserSelect,
                  },
                }}
              />
            ) : null,
          )}
        </Box>
      );
    }
    return (
      <Box sx={{ p: theme.spacing(4, 6) }}>
        {collection.creator && (
          <PermissionMemberItem
            userProfile={collection.creator}
            entityOrganizationId={collection.organizationId}
            type="owner"
          />
        )}

        {filterExistingMembers.map((inviteMember) => (
          <CollectionPermissionMember
            key={inviteMember.id}
            collectionOrganizationId={collection.organizationId}
            collectionInviteMember={inviteMember}
            readonly={!hasFullAccess}
            onPermissionChange={handleInviteMemberPermissionChange}
          />
        ))}
      </Box>
    );
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    isInputFocused,
    inviteMembers,
    collection,
    inputValue,
    suggestedUsers,
    filterExistingMembers,
    hasFullAccess,
  ]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: showConfirmation ? 'flex' : 'none',
          alignItems: showConfirmation ? 'flex-start' : 'center',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
          backgroundColor: theme.colors?.primary.parchment,
          gap: 2,
          padding: theme.spacing(4, 6),
        }}
      >
        <CollectionPermissionUpdatesDialog
          collection={collection}
          addedUserEmails={
            existingInputData?.inviteMembers?.members.map((m) => m.email) || []
          }
          generalPermissionUpdate={
            existingInputData?.generalPermission?.generalPermission
          }
          removedUserIds={existingInputData?.removeMembers?.userIds || []}
          onBackPress={() => setShowConfirmation(false)}
          loading={savingData}
          onClickYes={async () => {
            if (existingInputData) {
              await handleUpdateNestedCollectionPostPermissions(
                existingInputData,
                [
                  {
                    excludedChildCollectionIds: [],
                    excludedPostIds: [],
                    mode: 'selectedAll',
                    collectionId: collection.id,
                    nestedEntities: [],
                  },
                ],
              );
            }
            onCallbackAfterUpdate?.({ showCustomSelectionView: false });
          }}
          onClickCustomSelect={async () => {
            setShowConfirmation(false);
            onCallbackAfterUpdate?.({ showCustomSelectionView: true });
          }}
        />
      </Box>

      <Box display={showConfirmation ? 'none' : 'flex'} flexDirection="column">
        <Box
          sx={{
            position: 'relative',
            borderRadius: theme.spacing(6, 6, 0, 0),
            flexDirection: 'column',
            backdropFilter: 'blur(20px)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              backgroundColor: `rgba(250, 243, 236, 0.85)`,
              gap: 2,
              padding: theme.spacing(4, 6),
              ...(newSelectedUsers.length
                ? { paddingLeft: 4, alignItems: 'flex-start' }
                : {}),
              maxHeight: 110,
              overflowY: 'auto',
              '::-webkit-scrollbar': {
                display: 'none',
              },
              scrollbarWidth: 0,
              msOverflowStyle: 'none',
            }}
          >
            {hasFullAccess ? (
              renderInput({
                sx: { width: '100%' },
                inputRef: searchInputRef,
              })
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: 2,
                }}
              >
                <IconBoldInfoCircle
                  size={16}
                  color={theme.colors?.utility[600]}
                  style={{
                    marginTop: 2,
                  }}
                />
                <Typography
                  variant="headline-sm"
                  color={theme.colors?.utility[600]}
                >
                  Only people with full access can change permissions
                </Typography>
              </Box>
            )}
            {renderCopyButton()}
          </Box>
          <Box
            sx={{
              overflowY: 'auto',
              backgroundColor: 'rgba(250, 243, 236, 0.8)',
              height: 408,
            }}
          >
            {!inputValue && newSelectedUsers.length ? (
              renderPermissionSelectView()
            ) : (
              <>
                {(invitedUser ||
                  ((collection.permissionRequests || [])?.length > 0 &&
                    collection.creator.id === user?.id)) && (
                  <Box
                    padding={theme.spacing(6, 6, 0, 6)}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: 6,
                    }}
                  >
                    {invitedUser ? (
                      <PermissionCreateRequest
                        entityOrganizationId={collection.organizationId}
                        onRequestCreateAccess={onRequestCreateAccess}
                        permissionLevel={invitedUser['permissionLevel']}
                        isRequestCreated={collection.permissionRequests?.some(
                          (r) => r.user.id === user?.id,
                        )}
                      />
                    ) : (
                      collection.creator.id === user?.id &&
                      (collection.permissionRequests || []).length > 0 && (
                        <>
                          <Typography
                            variant="headline-xs"
                            color={theme.colors?.utility[700]}
                          >
                            {(collection.permissionRequests || []).length}{' '}
                            person requested access
                          </Typography>

                          {collection.permissionRequests?.map((request) => (
                            <PermissionApproveRequest
                              entityOrganizationId={collection.organizationId}
                              request={request}
                            />
                          ))}
                        </>
                      )
                    )}
                    <Divider
                      sx={{
                        borderColor: 'rgba(35, 6, 3, 0.07)',
                        borderWidth: 0.5,
                      }}
                    />
                  </Box>
                )}

                {renderMembers}
              </>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            backdropFilter: hasFullAccess ? 'blur(20px)' : 'none',
          }}
        >
          {collection.generalPermission && (
            <GeneralPermissionSelector
              readonly={!hasFullAccess}
              entityType="collection"
              organizationId={collection.organizationId}
              organizationName={collection.organizationName}
              initialGeneralPermission={collection.generalPermission}
              initialPermissionLevel={
                collection.permissionLevel || PermissionLevel.View
              }
              onGeneralPermissionChange={(
                generalPermission,
                permissionLevel,
              ) => {
                setOverallPermission((draft) => {
                  draft.generalPermission = generalPermission;
                  draft.permission = permissionLevel;
                });
              }}
              componentProps={{
                sx: {
                  bgcolor: 'rgba(250, 243, 236, 0.85)',
                  backdropFilter: 'blur(20px)',
                  p: theme.spacing(4, 6),
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderRadius: hasFullAccess ? 0 : theme.spacing(0, 0, 6, 6),
                },
              }}
            />
          )}

          <Button
            ref={updateBtnRef}
            disabled={isUpdateDisabled}
            variant="primary-alt"
            fullWidth
            onClick={updatePermissions}
            sx={{
              height: 42,
              borderRadius: theme.spacing(0, 0, 6, 6),
              opacity: hasFullAccess ? 1 : 0,
            }}
            startIcon={loading ? <CircularProgress size="1rem" /> : null}
          >
            Update
          </Button>
        </Box>
        {renderCopyLinkToast()}
      </Box>
    </Box>
  );
};
