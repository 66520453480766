import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LicenseInfo as MUILicenseInfo } from '@mui/x-license-pro';
import { GlobalDropzoneLayer } from 'components/common/Dropzone/GlobalDropzoneLayer';
import { AuthRoute } from 'components/layouts/AuthRoute';
import { CustomHeaderContextProvider } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import { RoleBasedUserAccessContextProvider } from 'contexts/RoleBasedUserAccess.context';
import {
  Layer2RouteView,
  Layer3RouteView,
  MainRouteView,
} from 'features/routing';
import moment from 'moment';
import { WebViewRichTextEditorPage } from 'pages/webViewRichTextEditor';
import { lazy, Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Toaster } from 'react-hot-toast';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { theme } from 'styles/theme/theme';
import { ErrorBoundary } from './components/common/ErrorBoundary';
import { ApolloProvider } from './contexts/Apollo.context';
import { FeatureFlagContextProvider } from './contexts/FeatureFlag.context';
import { CommandContextProvider } from './contexts/commands';
import { PendingActionsProvider } from './contexts/users/PendingActions';
import {
  UserContextConsumer,
  UserContextProvider,
} from './contexts/users/User.context';
import { DndContextProvider } from './features/dnd';
import './styles/index.css';

// Lazyload all the pages
const ForgotPassword = lazy(() =>
  import('./pages/auth/forgot-password').then((module) => ({
    default: module.ForgotPassword,
  })),
);
const Login = lazy(() =>
  import('./pages/auth/index').then((module) => ({ default: module.Login })),
);
const Register = lazy(() =>
  import('./pages/auth/register').then((module) => ({
    default: module.Register,
  })),
);
const ResetPassword = lazy(() =>
  import('./pages/auth/reset-password').then((module) => ({
    default: module.ResetPassword,
  })),
);
const VerifyWorkosAuthCode = lazy(() =>
  import('./pages/auth/verifyWorkosAuthCode').then((module) => ({
    default: module.VerifyWorkosAuthCode,
  })),
);
const WorkosAuth = lazy(() =>
  import('./pages/auth/workosAuth').then((module) => ({
    default: module.WorkosAuth,
  })),
);
const PublicVerifyEmailPage = lazy(() =>
  import('./pages/verify').then((module) => ({
    default: module.PublicVerifyEmailPage,
  })),
);

MUILicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY || '');

moment.updateLocale('en', {
  relativeTime: {
    past: '%s ago',
    s: 'a few seconds',
    ss: '%ds',
    m: 'a minute',
    mm: '%dm',
  },
});

const isDev = process.env.NODE_ENV === 'development';

export const App = () => {
  const location = useLocation();

  useEffect(() => {
    import('react-pdf')
      .then(({ pdfjs }) => {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
      })
      .catch(() => {
        console.log('pdfjs worker supported');
      });
  }, []);

  if (location.pathname === '/rich-text-editor') {
    return (
      <ThemeProvider theme={theme}>
        <ApolloProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <UserContextProvider>
              <WebViewRichTextEditorPage />
            </UserContextProvider>
          </LocalizationProvider>
        </ApolloProvider>
      </ThemeProvider>
    );
  }

  return (
    <Suspense>
      <ThemeProvider theme={theme}>
        <ApolloProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <ErrorBoundary>
              <Helmet>
                <title>Plot — Workspace for Creatives</title>
              </Helmet>

              <UserContextProvider>
                <UserContextConsumer>
                  {({ user }) =>
                    !user ? (
                      <Routes>
                        <Route path="/auth" element={<AuthRoute />}>
                          <Route path="/auth" element={<Login />} />
                          <Route path="/auth/workos" element={<WorkosAuth />} />
                          <Route path="/auth/register" element={<Register />} />
                          <Route
                            path="/auth/workos/verify"
                            element={<VerifyWorkosAuthCode />}
                          />
                          <Route
                            path="/auth/forgot"
                            element={<ForgotPassword />}
                          />
                          <Route
                            path="/auth/reset"
                            element={<ResetPassword />}
                          />
                        </Route>
                        <Route
                          path="/verify"
                          element={<PublicVerifyEmailPage />}
                        />
                        <Route
                          path="*"
                          element={
                            <Navigate
                              to={`/auth/workos?redirect=${window.location.pathname}`}
                            />
                          }
                        />
                      </Routes>
                    ) : (
                      <FeatureFlagContextProvider>
                        <RoleBasedUserAccessContextProvider>
                          <PendingActionsProvider>
                            <CommandContextProvider>
                              <GlobalDropzoneLayer>
                                <CustomHeaderContextProvider>
                                  <DndContextProvider>
                                    <MainRouteView />
                                    <Layer2RouteView />
                                    <Layer3RouteView />
                                  </DndContextProvider>
                                </CustomHeaderContextProvider>
                              </GlobalDropzoneLayer>
                            </CommandContextProvider>
                          </PendingActionsProvider>
                        </RoleBasedUserAccessContextProvider>
                      </FeatureFlagContextProvider>
                    )
                  }
                </UserContextConsumer>
              </UserContextProvider>
            </ErrorBoundary>
          </LocalizationProvider>
          <Toaster />
        </ApolloProvider>
      </ThemeProvider>

      {/* Fake intercom */}
      {isDev && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            right: 0,
            width: 48,
            height: 48,
            m: '20px',
            borderRadius: 6,
            bgcolor: theme.colors?.primary.maroon,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            zIndex: 999999,
          }}
        >
          Hi
        </Box>
      )}
    </Suspense>
  );
};

export default App;
